import React, {useEffect,useState} from "react";
import './Project.css';
//import axios from "axios";
import DescriptionIcon from '@material-ui/icons/Description';
import YouTubeIcon from '@material-ui/icons/YouTube';
import imgp31 from '../images/projects/project3-1.jpeg';
import imgp32 from '../images/projects/project3-2.jpeg';
import imgp33 from '../images/projects/project3-3.jpeg';
import imgp34 from '../images/projects/project3-4.jpeg';
import imgp35 from '../images/projects/project3-5.jpg';
import AOS from 'aos';


const Project_info3 = ({image_link,description,title,report_link,video_link}) => {
  useEffect(() => {
    AOS.init({
      duration : 2000
    });
  }, []);
  return (
      <div className="info3" data-aos="fade-up" data-aos-once="true">
          <img className="img_info3" src={image_link} data-aos="fade-in" data-aos-once="true" alt=""/> 
          <div className="desc_info" data-aos="fade-in" data-aos-once="true">
            <h1>{title}</h1>
            <p>{description}</p>
            <div className="info-button" data-aos="fade-in" data-aos-once="true">
             <button className="info-btn" onClick={()=> window.open(report_link, "_blank")}><DescriptionIcon fontSize="large"/> Report</button>
             <button className="info-btn" onClick={()=> window.open(video_link, "_blank")}><YouTubeIcon fontSize="large"/> Video</button>
            </div>
          </div>
      </div>
  )
}

function Projects3() {
    //const [project, setproject] = useState([]);
    //axios.get("https://saenitk.herokuapp.com/projects/").then((res)=>{ setproject(res.data)}).catch((err)=>console.log(err));
    return (
        <div className="project">
            <div className="head_p">
                <h1 className="pp1">Project - Expo</h1>
                <h1 className="pp2" >2021-22</h1>
            </div>
            <div className="p_info">
            {
                //project.map((item)=>(item.academic_year==="20-21" && <Project_info2 {...item} key={item.id}/>))
            }
            </div>
            <Project_info3 
              image_link={imgp31}
              title="F1 Tubercles Biomimicry"
              description="This project compares the aerodynamic characteristics of the actual rear 
              wing of an F1 car and a tubercle leading edge on the rear wing. This project is 
              inspired by nature – humpback whales – which exhibit excellent aerodynamic abilities
               underwater despite being one of the heaviest beings on the planet. The optimum 
               airfoil is found using XFLR5, designed in Fusion 360, and analyzed using ANSYS."
              report_link="https://drive.google.com/file/d/1U6uigXYm7c6ikRpbs_iG5hcft3M62DT-/view?usp=sharing"
              video_link="https://www.youtube.com/watch?v=GDJcKC-UMiM"
            />
            <Project_info3
              image_link={imgp32}
              title="Kinematic Analysis and Trajectory Planning for 6 DOF Robot Manipulator"
              description="In the era of 4.0 technology, robotic arms are becoming more and more 
              popular in modern industries. Today, every small and large industry uses the robotic
               manipulator to complete various tasks like picking and placing, welding process, 
               painting, and material handling. Therefore, the research and simulation of robotic 
               arms significantly improve the efficiency of using this tool in all sectors. The 
               Unimation PUMA 560 serially linked robot manipulator was used as a basis because
              this robot manipulator is widely used in industry and academia. It has nonlinear 
              and uncertain dynamic parameters serial link 6 degrees of freedom robot manipulator.
              The position and orientation of the robotic arm end-effector are generated by the 
              Denavit Hartenburg method, and the inverse kinematics equations have been derived 
              using the analytical method. Cubic and quintic polynomial interpolations have been 
              used to study trajectory planning, and the Monte Carlo method is proposed to analyze 
              the robotic arm's workspace."
              report_link="https://docs.google.com/document/d/1mzWw8-1x42msORFGYUII2BxJbofbFhvC/edit?usp=sharing&ouid=112128752698723954612&rtpof=true&sd=true"
              video_link="https://www.youtube.com/watch?v=86owP2emEyQ"
            />
            <Project_info3 
              image_link={imgp33}
              title="Solar based Wireless Power Transmission"
              description="A literature survey is done on wireless power transmission. Simulations 
              were done to study the power transmitted using different coil distances and materials.
              Solar power is studied in detail. A detailed study of how solar energy which is DC 
              can be made useful for wireless power transfer using converters. The point of maximum
              power is drawn from the graphs. Future development of the project would be a 
              detailed analysis of load requirements, solar panel modeling, and dc to WPT for
              electric vehicle charging."
              report_link="https://docs.google.com/document/d/190VR4lrJ4jZCM4Tcx-PyWaYFGFZ5wY33/edit?usp=sharing&ouid=112128752698723954612&rtpof=true&sd=true"
              video_link="https://www.youtube.com/watch?v=CWHtGwKRd40"
            />
            <Project_info3
              image_link={imgp34}
              title="Active Suspension Using Matlab"
              description="Vehicle suspension systems are typically rated by their ability to 
              provide good road handling and better ride comfort. Current automobile suspension 
              systems use passive components only by utilizing spring and damping coefficients with 
              fixed rates. This system only offers a compromise between these two conflicting 
              criteria. Spring and damper are used between the vehicle body and the wheel assembly 
              in conventional passive suspensions. The parameters of the spring-damper are chosen 
              to emphasize one of several conflicting interests, like passenger comfort, road 
              handling, and suspension deflection. Using a feedback-controller hydraulic actuator
              between the body and wheel assembly, active suspensions allow the designer to
              balance these requirements. Active suspension controls the vertical wheel movement
               with an onboard system rather than determined only by the road profile. Team members 
               were able to gain valuable experience in applying various concepts such as 
               mathematical modeling, PID controllers, and the basics of H- infinity synthesis 
               and MATLAB. However, the project still has a lot of scope for improvement in terms 
               of improving the efficiency of the system and introducing various other aspects such 
               as different control modes, etc."
              report_link="https://docs.google.com/document/d/155r9bR8nv0aBp7oz2JN_BEmpdzd323WY/edit?usp=sharing&ouid=112128752698723954612&rtpof=true&sd=true"
              video_link="https://www.youtube.com/watch?v=yRwK8Ci4jrk&t=4s"
            />
            <Project_info3
             image_link={imgp35}
             title="Velocity prediction and Road Lane Detection with Python"
             description="Our project is divided into 2 modules. Road curvature module: In this 
             module, we first developed a module to detect lanes in a road using AI&ML. Using this
              data we then calculate the radius of the curvature of the road at each point of the 
              frame. This data is then sent to the next module. Velocity prediction module: In this
              module, we take the given radius of curvature of the road. Other factors such as 
              slip angle steering angle calculated. Combining the calculated radius of curvature 
              and the maximum speed for a safe turn is calculated The maximum speed is displayed 
              on the screen for assisting drivers or for calculating the safe speed for 
              self-driving vehicles."
             report_link=""
             video_link="https://www.youtube.com/watch?v=Lbmv9D2h_Oc&t=14s"
            />
         </div>
         
    )
}

export default Projects3